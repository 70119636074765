import React from "react"
import SEO from "../components/seo"

import { CardLeft, CardRight, Line } from "../components/elements"
import Layout from "../components/layout"
import {
  HeaderPage,
  IntroSection,
  Services,
  ProductDetails,
  ReasonSection,
  ServiceDetail,
} from "../components/structure"

import headerIcon from "../images/web/headerIcon.svg"
import newwebsite from "../images/web/newwebsite.svg"
import responsive from "../images/web/responsive.svg"
import seo from "../images/web/seo.svg"
import upgrade from "../images/web/upgrade.svg"
import webmaintenace from "../images/web/webmaintenace.svg"

export default function WebsiteDesignDevelopment() {
  return (
    <Layout>
      <SEO title="Website Design & Development" />
      <HeaderPage
        src={headerIcon}
        color={props => props.theme.procolors.webDark}
        bgColor={props => props.theme.procolors.webLight}
        title="Your website done your way."
        tagline="Using modern tools and techniques, we’ll design and develop the perfect website for your business."
        catchy="Website Design & Development"
      />
      <Line />
      <ServiceDetail>
        <IntroSection
          title="Your website done your way."
          details="Website design is one of eDigitalHub’s primary services. By creating an effective website for your business, we will help you to build brand awareness, drive traffic and connect with your customers. The end result of a quality website is an increase in sales and significant business growth."
          color={props => props.theme.procolors.webDark}
        />
        <ProductDetails
          to="/contact/"
          title="As part of our website design and development services, we offer:"
          color={props => props.theme.procolors.webDark}
        >
          <CardLeft
            alt="Brand new websites"
            title="Brand new websites"
            detail="Create a brand new fully functioning website that will let your customers know who you are and what you offer."
            src={newwebsite}
          />
          <CardRight
            alt="Upgrades on existing websites"
            title="Upgrades on existing websites"
            detail="Upgrade your existing website to make the user experience as convenient as possible and stand out from the sea of competition."
            src={upgrade}
          />
          <CardLeft
            alt="Websites that are mobile-friendly"
            title="Websites that are mobile-friendly"
            detail="Our websites are mobile-friendly, making them much more accessible to your target audience."
            src={responsive}
          />
          <CardRight
            alt="Websites that are SEO-friendly"
            title="Websites that are SEO-friendly"
            detail="Don’t worry about a thing as our team of dedicated tech gurus take care of all your website maintenance."
            src={seo}
          />
          <CardLeft
            alt="Website maintenance"
            title="Website maintenance"
            detail="Don’t worry about a thing as our team of dedicated tech gurus take care of all your website maintenance."
            src={webmaintenace}
          />
        </ProductDetails>
        <ReasonSection
          borderColor={props => props.theme.procolors.webLight}
          title="Why Do You Need Website Design and Development?"
          details="Websites today are crucial when it comes to the success of a business. Effective website design creates brand awareness and tells your story. It will let your customers know who you are, what products and services you offer, and why they should choose you."
          details1=" When optimised, a website attracts traffic and converts visitors into leads for your business, which in turn will increase sales and cause growth."
          details2="Aside from providing your customers with information, you can also make direct sales through your website with eCommerce, or an online shopping platform"
        />
      </ServiceDetail>
      <Line />
      <Services />
    </Layout>
  )
}
